
.header{
    cursor: pointer;
    border: solid 1px #e7e7e7;
    padding: 5px;
    background-color: #f8f8f8;
    color: #777;
    font-family: verdana;
}

.content{
    cursor: pointer;
    border-left: solid 1px #e7e7e7;
    border-right: solid 1px #e7e7e7;
    border-bottom: solid 1px #e7e7e7;
    border-radius: 0 0 5px 5px;
    padding: 15px;
    font-family: verdana;
    font-size: 14px;
}
