@media all and (min-width: 480px) {
    .formOuter {
        padding: 60px 0;
    }

    .formOuter form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.form-label {
    display: inline-block;
    width: 80px;
    text-align: left;
}


.buildResultContainer {
    text-align: left;
    border: 1px solid;
    padding: 5px;
}
div.buildResultContainer{
    margin-top: 0.625em;
}

.status-label-READY {
    color: green;
    font-weight: bold;
}

.status-label-ERROR {
    color: red;
    font-weight: bold;
}

.status-label-TIMEOUT {
    color: red;
    font-weight: bold;
}

.status-label-PENDING {
    color: #1BAEFF;
    font-weight: bold;
}

.spinning.glyphicon {
    margin-right: 7px;
    margin-left: 10px;
    top: 2px;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.resultOuter {
    text-align: left;
    border: 0px solid;
    padding: 5px;
}

.lander {
    padding: 80px 0;
    text-align: center;
}

.lander h1 {
    font-weight: 600;
}

.lander p {
    color: #999;
}

