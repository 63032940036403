body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.LoaderButton {
  background-color: #009b4f;
  color: white;
  font-weight: 700;
}

.LoaderButton:focus  {
  background: #006338;
  color: white;
}

.LoaderButton:hover  {
  background: #006338;
  color: white;
}


.NotFound {
  padding-top: 100px;
  text-align: center;
}
@media all and (min-width: 480px) {
    .formOuter {
        padding: 60px 0;
    }

    .formOuter form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.form-label {
    display: inline-block;
    width: 80px;
    text-align: left;
}


.buildResultContainer {
    text-align: left;
    border: 1px solid;
    padding: 5px;
}
div.buildResultContainer{
    margin-top: 0.625em;
}

.status-label-READY {
    color: green;
    font-weight: bold;
}

.status-label-ERROR {
    color: red;
    font-weight: bold;
}

.status-label-TIMEOUT {
    color: red;
    font-weight: bold;
}

.status-label-PENDING {
    color: #1BAEFF;
    font-weight: bold;
}

.spinning.glyphicon {
    margin-right: 7px;
    margin-left: 10px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.resultOuter {
    text-align: left;
    border: 0px solid;
    padding: 5px;
}

.lander {
    padding: 80px 0;
    text-align: center;
}

.lander h1 {
    font-weight: 600;
}

.lander p {
    color: #999;
}



.Build {
  vertical-align: top;
}








.header{
    cursor: pointer;
    border: solid 1px #e7e7e7;
    padding: 5px;
    background-color: #f8f8f8;
    color: #777;
    font-family: verdana;
}

.content{
    cursor: pointer;
    border-left: solid 1px #e7e7e7;
    border-right: solid 1px #e7e7e7;
    border-bottom: solid 1px #e7e7e7;
    border-radius: 0 0 5px 5px;
    padding: 15px;
    font-family: verdana;
    font-size: 14px;
}


.Deploy {
    vertical-align: top;
}







.Undeploy {
    vertical-align: top;
}






